import React, { useState } from 'react';
import styled from 'styled-components'; // Impor
import Work from './Work'; // Update the path to where your Work.js file is located
import NavigationBar from './NavigationBar';
import WorkWebsites from './WorkWebsites';

const BackgroundProject = styled.div`
  background-color: #111111;  
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  // Add other styling as needed
`;

const ProjectHeader = styled.h2`
  text-align: center;
  font-family: 'Valencia', sans-serif; // Use the same font family as your skill cards
  font-size: 6rem; // Adjust the size as needed
  margin-top: 5rem; // Add some space on the top
  color: white;
  position: absolute;
  width: 100%;
  text-decoration: underline;



`;



const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  right: 10%;
  position: absolute;
  top: 100px; // Adjust as needed
  background-color: #111111; // Or any color that fits your design
  color: white;
`;

const Tab = styled.button`
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-family: 'Avenir Roman', sans-serif;
  font-size: 26px;
  transition: all 0.3s ease;
  border: 1px solid gray;
  color: white;


  &:hover {
    background-color: #444242;
  }

  &.active {
    font-weight: bold;
    text-decoration: underline;
  }
`;
    
const ProjectsSection = () => {
  const [activeTab, setActiveTab] = useState('AI Applications');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <BackgroundProject>
      <NavigationBar style={{ position: 'fixed' }} />
      <ProjectHeader>My Projects</ProjectHeader>
      
      <TabContainer>
      
        <Tab onClick={() => handleTabClick('AI Applications')} className={activeTab === 'AI Applications' ? 'active' : ''}>
          AI Applications
        </Tab>
        <Tab onClick={() => handleTabClick('Websites')} className={activeTab === 'Websites' ? 'active' : ''}>
          Websites
        </Tab>
      </TabContainer>
      
      {activeTab === 'AI Applications' && <Work />}
      {activeTab === 'Websites' && <WorkWebsites />}
      {/* Conditional content based on the tab */}
    </BackgroundProject>
  );
};

export default ProjectsSection;