import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FaGithub, FaLinkedinIn, FaEnvelope } from 'react-icons/fa';


const Navwhite = styled.nav`
  padding: 1rem 2rem; // Added horizontal padding
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  font-size: 1rem;
  z-index: 1000;
  max-height: 30px;
  position: absolute;
`;

const StyledLinkwhite = styled(NavLink)`
  color: black;
  text-decoration: none;
  font-family: 'Avenir Helvetica Ariel';
  margin-right: 2rem; // Space between the links

  &.active {
    color: lightgrey;
    text-decoration: underline;
  }

  &:hover {
    color: lightgrey;
  }
`;

const LinksContainerWhite = styled.div`
  display: flex;
  margin-left: 25%;
`;

const IconsContainerwhite = styled.div`
  margin-left: auto; // This will push the icons to the right
  display: flex;
  gap: 1rem;
  margin-right: 25%;
`;

const IconLinkwhite = styled.a`
  color: black;
  font-size: 25px;
  transition: color 0.2s;

  &:hover {
    color: gray;
    transform: scale(1.1);
  }
`;

const NavigationBarwhite = ({ style }) => {
  
  return (
    <Navwhite style={style}>
      <LinksContainerWhite>
        <StyledLinkwhite to="/">Home</StyledLinkwhite>
        <StyledLinkwhite to="/projects">Projects</StyledLinkwhite>
      </LinksContainerWhite>
      {/* Rest of your links */}
      <IconsContainerwhite>
        <IconLinkwhite href="https://github.com/thoraxell" target="_blank" aria-label="GitHub">
        <FaGithub />
        </IconLinkwhite>
        <IconLinkwhite href="mailto:thor.axell0@gmail.com" aria-label="Email">
        <FaEnvelope />
        </IconLinkwhite>
        <IconLinkwhite href="https://www.linkedin.com/in/thor-axell-70501a209/" target="_blank" aria-label="LinkedIn">
        <FaLinkedinIn />
        </IconLinkwhite>
      </IconsContainerwhite>
      
    </Navwhite>
  );
};

export default NavigationBarwhite;
