import React, { Component } from 'react';
import Main from './Main';
//import AboutMe from './AboutMe';

class Hero extends Component {
  render() {
    return (
      <React.Fragment>
        <Main />
        
      </React.Fragment>
    );
  }
}

export default Hero;
