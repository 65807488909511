import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FaGithub, FaLinkedinIn, FaEnvelope } from 'react-icons/fa';


const Nav = styled.nav`
  background: #111111;
  padding: 1rem 2rem; // Added horizontal padding
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  z-index: 1000;
  max-height: 30px;
  
  position: absolute;
  
`;
const StyledLink = styled(NavLink)`
  color: white;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  margin-right: 2rem;
  

  &.active {
    color: lightgrey; // Change color for active link
    text-decoration: underline; // Add underline for active link
  }

  &:hover {
    color: lightgrey; // Keep hover style consistent
  }
`;


const LinksContainer = styled.div`
  display: flex;
  margin-left: 25%;
`;
const IconsContainer = styled.div`
  margin-left: auto; // This will push the icons to the right
  display: flex;
  gap: 1rem;
  margin-right: 25%;
`;

const IconLink = styled.a`
  color: white;
  font-size: 25px;
  transition: color 0.2s;

  &:hover {
    color: gray;
    transform: scale(1.1);
  }
`;

const NavigationBar = ({ style }) => {


  return (
    <Nav style={style}>
      <LinksContainer>
        <StyledLink to="/" activeClassName="active">
          Home
        </StyledLink>
        <StyledLink to="/projects" activeClassName="active">
          Projects
        </StyledLink>
      </LinksContainer>
      {/* Rest of your links */}
      <IconsContainer>
        <IconLink href="https://github.com/thoraxell" target="_blank" aria-label="GitHub">
        <FaGithub />
        </IconLink>
        <IconLink href="mailto:thor.axell0@gmail.com" aria-label="Email">
        <FaEnvelope />
        </IconLink>
        <IconLink href="https://www.linkedin.com/in/thor-axell-70501a209/" target="_blank" aria-label="LinkedIn">
        <FaLinkedinIn />
        </IconLink>
      </IconsContainer>
    </Nav>
  );
};

export default NavigationBar;
