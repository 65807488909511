import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import device from '../../../Assets/Responsive/breakpoints';

const Stage = styled.div`
position: relative;
/* border:1px solid black; */
z-index: 1;
`;

const moveUp = init => keyframes`
0%{
    transform: translateY(${init}px);
    opacity: 0;
}
100%{
    transform: translateY(0px);
    opacity: 1;
}
`;

const hideWhiteBlocks = () => keyframes`
0%{
    opacity: 1;
    height: 35vh;
}
100%{
    opacity: 0;
    height: 0vh;
}
`;


const TitleText = styled.div`
  flex: 1; // Each text takes up equal space
  text-align: center; // Center text
  white-space: pre-wrap; // Allow line breaks
  font-family: ${props => props.fontFam};
  opacity: 0;
  transform: translateY(20px); // Start slightly off-screen
  font-size: 50px;
  animation: ${props => (props.reveal ? moveUp(50) : 'none')} 1s cubic-bezier(0, 0.1, .12, .99) forwards;
  transform: translateY(${50 * 1.4}px);
`;



const WhiteBlock = styled.div`
position: absolute;
background-color: #111111;
margin-right:100%;
width: 120%;
height: 35vh;
animation: ${hideWhiteBlocks} 0.5s linear forwards;
animation-delay: 2s;
/* border: 1px solid grey; */
`;


class TitleReveal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reveal: false,
    };
    this.revealText = this.revealText.bind(this);
  }

  componentDidMount() {
    const { timeDelay } = this.props;
    this.revealText(timeDelay);
  }

  revealText(timeout) {
    setTimeout(() => {
      this.setState({ reveal: true });
    }, timeout);
  }

  render() {
    const { text, fontFam } = this.props;
    const { reveal } = this.state;

    // Assuming the text prop is passed as "first part / second part"
    const [firstPart, secondPart, thirdPart] = text.split(" @ ");

    return (
      <Stage>
        
        <TitleText fontFam={fontFam} reveal={reveal}>
          {firstPart}
        </TitleText>
        <TitleText fontFam={fontFam} reveal={reveal}>
          {secondPart}
        </TitleText>
        
        
        <WhiteBlock />
      </Stage>
    );
  }
}

TitleReveal.propTypes = {
  text: PropTypes.string.isRequired,
  fontFam: PropTypes.string,
  timeDelay: PropTypes.number.isRequired,
};

TitleReveal.defaultProps = {
  fontFam: 'Avenir Helvetica Ariel',
};
export default TitleReveal;
