import React, { Component } from 'react';
import styled from 'styled-components';
import ReactFullpage from '@fullpage/react-fullpage'; // Import ReactFullpage
import NameReveal from './NameReveal';
import TitleReveal from './TitleReveal';
import SkillsSection from './SkillsSection';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import NavigationBar from './NavigationBar';
import './Animations.css'; // Import your Animations.css here


const FirstPage = styled.div`
  background-color: #111111;
  color: white; /* This will set the text color to white for all text inside FirstPage */
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  
`;
const SecondPage = styled.div`
  //background-color: #FBF8F8;
  background-color: white;
  color: black; /* This will set the text color to white for all text inside FirstPage */
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  
`;



const ScrollDownArrow = styled.div`
  position: absolute;
  bottom: 1px;
  left: 50%; // Center horizontally
  transform: translateX(-50%); // Centering adjustment
  font-size: 50px; // Arrow size
  color: white; // Arrow color
  opacity: 0.5;
  
`;




class NameAndJobTitle extends Component {
  state = {
    currentSection: 0,
    direction: null,
    
  };
  

  

  handleLeave = (origin, destination, direction) => {
    this.setState({ currentSection: destination.index, direction: direction });
  };

  getAnimationClass = (sectionIndex) => {
    const { currentSection, direction } = this.state;
  
    // Apply 'zoomOut' when leaving the first section and moving to the second (scrolling down)
    if (sectionIndex === 0 && currentSection === 1 && direction === 'down') {
      return 'zoomOut';
    }
    // Apply 'zoomOut' when leaving the second section and moving to the first (scrolling up)
    else if (sectionIndex === 1 && currentSection === 0 && direction === 'up') {
      return 'zoomOut';
    }
    // Apply 'zoomIn' when arriving at the second section (scrolling down from the first section)
    else if (sectionIndex === 1 && currentSection === 1 && direction === 'down') {
      return 'zoomIn';
    }
    // Apply 'zoomIn' when arriving back at the first section (scrolling up from the second section)
    else if (sectionIndex === 0 && currentSection === 0 && direction === 'up') {
      return 'zoomIn';
    }
    return '';
  };


  

render() {
  return (
    <>
      <ReactFullpage
        scrollingSpeed={3000}
        onLeave={this.handleLeave}
        render={({ fullpageApi }) => (  
          <ReactFullpage.Wrapper>
            {/* First section */}
            <div className={`section ${this.getAnimationClass(0)}`} >
            <FirstPage>
              <NavigationBar />
                <NameReveal text="Thor Axell" fontFam="Valencia" timeDelay={500} />
                <br />
                <TitleReveal
                  text="Creating Human-Centered AI Applications"
                  fontFam="AvenirRoman"
                  timeDelay={1300}
                />
                
                <div className="scroll-indicator">
                <ScrollDownArrow>
                  <IoIosArrowDown />
                </ScrollDownArrow>
                </div>
              </FirstPage>
            </div>
            {/* Second section */}
            <div className={`section ${this.getAnimationClass(1)}`} >
              <SecondPage>
              
                <SkillsSection />
              </SecondPage>
            </div>
            {/* No additional sections */}
          </ReactFullpage.Wrapper>
        )}
      />
    </>
  );
}
}

export default NameAndJobTitle;