import React from 'react';
import styled from 'styled-components';
import { AiFillCode } from 'react-icons/ai'; // Example icons
import { FaRobot } from 'react-icons/fa';
import { FcAbout } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import NavigationBarWhite from './NavigationBarWhite';

const SkillCard = styled.div`
  flex: 0 1 calc(25% - 4rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  border-radius: 60px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  padding: 25px;
  letter-spacing: 0.5px;
  font-size: 20px;
  max-width: 300px;
  min-width: 300px;
  position: relative;
  height: 500px;

  &:hover {
    cursor: pointer;
    transform: translateY(-5px);
  }
`;

const SkillDetailsText = styled.div`
  p {
    font-family: 'Roboto', sans-serif;

  }
  a {
    color: blue;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const SkillDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SkillsSectionContainer = styled.div`
  display: flex;
  color: black;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
`;

const IconContainer = styled.div`
  font-size: 75px;
  color: orange;
  
`;



const FooterButton = styled.button`
  position: relative;
  bottom: 1px;
  background-color: green;
  color: white;
  width: 220px;
  height: 60px;
  font-size: 25px;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  margin-top: 30px;
  &:hover {
    cursor: pointer;
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
`;

const SkillParagraphHeader = styled.h3`
  font-weight: bold;
  font-size: 1.2rem;  // Adjust font size as needed
  color: black;  // Adjust color as needed
  margin-bottom: -10px;
`;

const SkillsSection = () => {
  const navigate = useNavigate();

  const goToProjects = () => {
    navigate('/projects');
  };

  return (
    <>
      <NavigationBarWhite />
      <SkillsSectionContainer>  
        {/* Skill Card 1 */}
        <SkillCard>
          <IconContainer><FcAbout /></IconContainer> 
          <SkillDetails>
            <SkillDetailsText>
            <SkillParagraphHeader>About me:</SkillParagraphHeader>
              <p>My name is Thor Axell and I'm currently getting my masters degree in Human-Centered AI, at the University of Gothenburg. I like to build AI tools and I run a web development company.</p>
            </SkillDetailsText> 
            <SkillDetailsText>
            <SkillParagraphHeader>My Resume:</SkillParagraphHeader>

              <p>My full resume can be found through the link below.</p>
              <a href="/resume" target="_blank" rel="noopener noreferrer">Resume</a>
            </SkillDetailsText>
          </SkillDetails>
        </SkillCard>
        {/* Skill Card 2 */}
        <SkillCard>
        <IconContainer>
      <FaRobot style={{ color: 'green' }} />
      </IconContainer>

          <SkillDetails>
            <SkillDetailsText>
            <SkillParagraphHeader>Human-Centered AI:</SkillParagraphHeader>
              <p>My primary goal is to develop augmented AI tools intended to enhance human capabilities, while also focusing on ethical, legal and moral aspects of using AI.</p>
            </SkillDetailsText>
            <SkillDetailsText>
            <SkillParagraphHeader>Language Fluency:</SkillParagraphHeader>

              <p>Python • • • • •<br></br>Javascript • • • • ◦ <br></br>Flutter/Dart • • • • ◦ <br></br>React • • • • ◦<br></br>SQL • • • ◦ ◦  </p>
              
              
            </SkillDetailsText>
          </SkillDetails>
        </SkillCard>
        {/* Skill Card 3 */}
        <SkillCard>
          <IconContainer><AiFillCode /></IconContainer>
          <SkillDetails>
            <SkillDetailsText>
            <SkillParagraphHeader>Web Development Company:</SkillParagraphHeader>

              <p>At Axellit, our expertise lies in creating custom-tailored websites, designed to cater to the unique requirements of each individual order by a client or a business.</p>
            </SkillDetailsText>
            <SkillDetailsText>
            <SkillParagraphHeader>Creating the Websites:</SkillParagraphHeader>

              <p>We primarily use Wordpress and javascript to build our websites, but on request or for specific instances we also use react and nodeJs.</p>
            </SkillDetailsText>
          </SkillDetails>
        </SkillCard>
      </SkillsSectionContainer>
      <FooterButton onClick={goToProjects}>My Projects</FooterButton>
    </>
  );
};

export default SkillsSection;
