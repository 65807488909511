import React from 'react';
import ResumePDF from './Resume.pdf'; 

const Resume = () => {
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <object
        data={ResumePDF}
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <p>Your browser does not support PDFs. 
           <a href="Resume.pdf">Download the PDF</a>.</p>
      </object>
    </div>
  );
};

export default Resume;
