import React, { Component } from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { createGlobalStyle } from 'styled-components';

import WideScreenHero from './Slides/WideScreen/HeroSlide/Hero';
import ProjectSection from './Slides/WideScreen/HeroSlide/ProjectSection';
import Resume from './Slides/WideScreen/HeroSlide/Resume';
import ModelWork from './Slides/WideScreen/HeroSlide/ModelWork';
import './Assets/index.css';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #D6D4D4; /* Sets the background color of the body */
    color: white; /* Sets the text color for the body */
    margin: 0;
    padding: 0;
  }
`;

const MobileMessage = () => (
  
    <div style={{
      height: '100vh', // Use the full height of the screen
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center', // Vertically center
      alignItems: 'center', // Horizontally center
      textAlign: 'center', // Center text alignment
      fontSize: '40px', // Increase font size
      color: '#333' // Change text color if needed
    }}>
      Mobile version is under development. Please open the portfolio from a computer.
    </div>
);

class App extends Component {
  componentDidMount() {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }

  render() {
    return (
      <Router>
        <GlobalStyle />
        <MediaQuery query="(min-device-width: 1224px)">
          <Routes>
            <Route path="/" element={<WideScreenHero />} />
            <Route path="/projects" element={<ProjectSection />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/model" element={<ModelWork />} />
            {/* Add additional routes as placeholders or actual components as needed */}
          </Routes>
        </MediaQuery>
        <MediaQuery query="(max-device-width: 1223px)">
          <MobileMessage />
        </MediaQuery>
      </Router>
    );
  }
}

render(React.createElement(App), document.getElementById('root'));